import { heading5, heading6, paragraph } from '../typography';
import inputStyleConfig from './input';
const registrationButtonBase = {
    borderRadius: 0,
    border: 'none',
    flex: 1,
    height: '60px',
    ...heading5(),
    _focus: {
        border: '2px dashed',
        borderColor: 'primary300',
        outlinePrimary400: 'none',
        boxShadow: 'none',
    },
    _disabled: {
        color: 'neutral300',
    },
};
const sizeProps = {
    fontSize: heading6().fontSize,
};
const ButtonComponentStyle = {
    baseStyle: {
        ...heading6(),
        borderWidth: 1,
        borderColor: 'transparent',
        borderRadius: 'lg',
        paddingInline: 4,
        _focus: {
            boxShadow: 'none',
        },
        _disabled: {
            opacity: 1,
            cursor: 'not-allowed',
            boxShadow: 'none',
        },
    },
    defaultProps: {
        variant: 'empty',
        size: 'lg',
    },
    sizes: {
        lg: sizeProps,
        md: sizeProps,
        sm: sizeProps,
        xs: sizeProps,
    },
    variants: {
        empty: {},
        registrationPrimary: {
            ...registrationButtonBase,
            color: 'primary500',
        },
        registrationSecondary: {
            ...registrationButtonBase,
            color: 'neutral400',
            borderLeft: '1px solid',
            borderLeftColor: 'neutral200',
        },
        primary500: {
            color: 'white',
            backgroundColor: 'primary500',
            _hover: {
                backgroundColor: 'primary600',
                _disabled: {
                    backgroundColor: 'neutral300',
                },
            },
            _focus: {
                borderColor: 'primary600',
                boxShadow: 'inset 0px 0px 0px 4px rgba(130, 197, 203, 0.25)',
            },
            _disabled: {
                backgroundColor: 'neutral300',
            },
        },
        primary100: ({ theme }) => ({
            color: 'primary500',
            backgroundColor: 'primary100',
            _hover: {
                backgroundColor: 'primary200',
                _disabled: {
                    backgroundColor: 'neutral100',
                },
            },
            _focus: {
                borderColor: 'primary300',
                boxShadow: theme.shadows.outlinePrimary400,
            },
            _disabled: {
                color: 'neutral400',
                backgroundColor: 'neutral100',
            },
        }),
        secondary100: ({ theme }) => ({
            color: 'secondary500',
            backgroundColor: 'secondary100',
            _hover: {
                backgroundColor: 'secondary200',
                _disabled: {
                    backgroundColor: 'neutral100',
                },
            },
            _focus: {
                borderColor: 'secondary300',
                boxShadow: theme.shadows.outlineSecondary400,
            },
            _disabled: {
                color: 'neutral400',
                backgroundColor: 'neutral100',
            },
        }),
        secondary400: ({ theme }) => ({
            color: 'white',
            backgroundColor: 'secondary400',
            _hover: {
                backgroundColor: 'secondary500',
                _disabled: {
                    backgroundColor: 'neutral300',
                },
            },
            _focus: {
                borderColor: 'secondary500',
                boxShadow: theme.shadows.outlineSecondary100,
            },
            _disabled: {
                backgroundColor: 'neutral300',
            },
        }),
        danger: {
            color: 'white',
            backgroundColor: 'secondary400',
            _hover: {
                backgroundColor: 'secondary300',
                _disabled: {
                    backgroundColor: 'secondary100',
                },
            },
            _focus: {
                borderColor: 'secondary200',
                boxShadow: 'inset 0px 0px 0px 4px rgba(240, 212, 178, 0.5)',
            },
            _disabled: {
                color: 'neutral400',
                backgroundColor: 'secondary100',
            },
        },
        selectButton: {
            // @ts-expect-error okay
            ...inputStyleConfig.baseStyle?.field,
            justifyContent: 'space-between',
            width: '100%',
            px: 3,
        },
        selectButtonOpen: {
            // @ts-expect-error okay
            ...inputStyleConfig.baseStyle?.field,
            // @ts-expect-error okay
            ...inputStyleConfig.variants?.selectInputOpen.field,
            // @ts-expect-error okay
            ...inputStyleConfig.baseStyle?.field._focus,
            justifyContent: 'space-between',
            width: '100%',
            px: 3,
            _hover: {
                borderColor: 'primary400',
            },
        },
        menuIcon: ({ theme }) => ({
            _focusVisible: {
                borderColor: 'primary300',
                boxShadow: theme.shadows.outlinePrimary400,
            },
            _hover: {
                borderColor: 'primary300',
                boxShadow: theme.shadows.outlinePrimary400,
            },
            _active: {
                borderColor: 'primary300',
                boxShadow: theme.shadows.outlinePrimary400,
                color: 'primary500',
                background: 'white',
            },
            color: 'neutral400',
            width: 14,
            height: 14,
            alignSelf: 'center',
        }),
        action: ({ theme }) => ({
            _hover: {
                borderColor: 'primary300',
                boxShadow: theme.shadows.outlinePrimary400,
                color: 'primary500',
            },
            _active: {
                borderColor: 'primary300',
                boxShadow: theme.shadows.outlinePrimary400,
                color: 'primary500',
            },
            _focusVisible: {
                borderColor: 'primary300',
                boxShadow: theme.shadows.outlinePrimary400,
                color: 'primary500',
            },
            transition: 'all 0.3s',
            borderColor: 'transparent',
            borderWidth: 1,
            borderRadius: 'md',
            color: 'neutral400',
            width: 10,
            height: 10,
            justifyContent: 'center',
        }),
        icon: ({ theme }) => ({
            _focusVisible: {
                boxShadow: theme.shadows.outlinePrimary400,
            },
            _hover: {
                boxShadow: theme.shadows.outlinePrimary400,
            },
        }),
        sideBarAction: ({ theme }) => ({
            _focusVisible: {
                boxShadow: 'none',
            },
            _hover: {
                boxShadow: 'none',
                backgroundColor: theme.colors.neutral100,
            },
            _disabled: {
                opacity: 0.5,
            },
        }),
        unstyled: ({ theme }) => ({
            fontFamily: paragraph().fontFamily,
            borderRadius: 'none',
            border: 'none',
            height: 'auto',
            _focusVisible: {
                boxShadow: theme.shadows.outlinePrimary400,
            },
        }),
    },
};
export default ButtonComponentStyle;
