import { BrowserTracing, init } from '@sentry/react';

import { appEnv } from '@izimi/core';

if (import.meta.env.PROD) {
  init({
    dsn: 'https://ce5dcca0f95f408e89cb8594a73e56ee@o548562.ingest.sentry.io/6391934',
    integrations: [new BrowserTracing()],
    environment: appEnv,
  });
}
