import { addDays, isSameDay } from 'date-fns';
import { convertLocalDateToUTCDate } from '@izimi/utils';
import DocumentEntity from './DocumentEntity';
export const mapDocumentToEntity = (document) => new DocumentEntity({
    id: document.id,
    name: document.name,
    categories: document.categories,
    labels: document.labels,
    isShareable: document.isShareable,
    isBeingScanned: document.isBeingScanned,
    isSeen: document.isSeen,
    creationDate: document.creationDate,
    modificationDate: document.modificationDate,
    scheduledTrashDate: document.scheduledTrashDate,
    scheduledDeleteDate: document.scheduledDeleteDate,
    isOwner: document.isOwner,
    size: document.size,
    trashingDate: document.trashingDate,
    sharedMessage: document.sharedMessage,
    owner: document.owner,
    sharedWith: document.sharedWith,
    source: document.source,
    sources: document.sources,
    state: document.state,
    isCopied: document.isCopied,
    hasVirus: document.hasVirus,
    metadata: document.metadata,
});
export const mapDocumentsToEntities = (documents = []) => documents.map(mapDocumentToEntity);
export const FIRST_OF_JANUARY_2019 = new Date(2019, 0, 1); // Earliest possible date of documents added to Izimi.
export const SOON_TO_BE_TRASHED_DAYS = 31;
export const getDocumentFilterParameters = ({ labels, ownerIds, receiverIds, sources, afterCreationDate, beforeCreationDate, onlySoonToBeTrashed, }) => {
    const filters = {};
    if (!isSameDay(afterCreationDate, FIRST_OF_JANUARY_2019)) {
        filters.afterCreationDate =
            convertLocalDateToUTCDate(afterCreationDate).toISOString();
    }
    if (!isSameDay(beforeCreationDate, new Date())) {
        filters.beforeCreationDate =
            convertLocalDateToUTCDate(beforeCreationDate).toISOString();
    }
    if (onlySoonToBeTrashed) {
        filters.beforeScheduledTrashDate = convertLocalDateToUTCDate(addDays(new Date(), SOON_TO_BE_TRASHED_DAYS)).toISOString();
    }
    if (labels.length) {
        filters.labels = labels;
    }
    if (ownerIds.length) {
        // TODO: api does not support multiple owners
        // eslint-disable-next-line prefer-destructuring
        filters.ownerId = ownerIds[0];
    }
    if (receiverIds.length > 0) {
        filters.sharedWith = receiverIds;
    }
    else {
        filters.sharedWith = undefined;
    }
    if (sources.length) {
        filters.sources = sources;
    }
    return filters;
};
export const getDocumentUserName = (user) => {
    if (user.type === 'CITIZEN') {
        return `${user.firstName} ${user.lastName}`;
    }
    if (user.type === 'STUDY') {
        return user.name;
    }
    return '';
};
