import { useMutation, useQuery } from '@tanstack/react-query';
import { useInvalidateContactListCache } from '../contacts';
import { useSetVaultPreferencesCache, vaultQueryKeys } from './cache';
import { mapVaultInfoToEntity } from './helpers';
const STALE_30_MINUTES = 1800000;
const createVaultModule = ({ vaultApi }) => {
    const useVaultInfo = () => {
        const { data, isLoading, error, refetch } = useQuery({
            queryKey: vaultQueryKeys.getVaultInfo(),
            queryFn: () => vaultApi.getVaultInfo().then(mapVaultInfoToEntity),
            staleTime: STALE_30_MINUTES,
        });
        return {
            vaultInfo: data,
            error,
            isLoading,
            refetch,
        };
    };
    const useVaultPreferences = () => {
        const { data, isLoading, error, refetch } = useQuery({
            queryKey: vaultQueryKeys.getPreferences(),
            queryFn: () => vaultApi.getPreferences(),
            staleTime: STALE_30_MINUTES,
        });
        return {
            vaultPreferences: data,
            error,
            isLoading,
            refetch,
        };
    };
    const useUpdateVaultPreferences = () => {
        const setVaultPreferencesCache = useSetVaultPreferencesCache();
        const invalidateContactsCache = useInvalidateContactListCache();
        const { mutateAsync, isPending, error } = useMutation({
            mutationFn: values => vaultApi.updatePreferences(values),
            onSuccess(preferences) {
                setVaultPreferencesCache(preferences);
            },
            onError: async ({ status }) => {
                // BE throws 404 when added contact no longer is a contact in some cases
                if (status === 404) {
                    await invalidateContactsCache();
                }
            },
        });
        return {
            updateVaultPreferences: mutateAsync,
            isPending,
            error,
        };
    };
    return {
        useVaultInfo,
        useVaultPreferences,
        useUpdateVaultPreferences,
    };
};
export default createVaultModule;
