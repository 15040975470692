export const notaryApiUrl = {
    local: 'http://localhost:8080',
    development: 'https://notary-dev-certinot.credoc.be',
    test: 'https://notary-test-certinot.credoc.be',
    acc: 'https://notary-acc.izimi.be',
    production: 'https://izimi.fednot.be',
};
export const citizenApiUrl = {
    local: 'http://localhost:8080',
    development: 'https://dev-certinot.credoc.be',
    test: 'https://test-certinot.credoc.be',
    acc: 'https://acc.izimi.be',
    production: 'https://app.izimi.be',
};
const apiUrls = {
    local: [notaryApiUrl.local, citizenApiUrl.local],
    development: [notaryApiUrl.development, citizenApiUrl.development],
    test: [notaryApiUrl.test, citizenApiUrl.test],
    acc: [notaryApiUrl.acc, citizenApiUrl.acc],
    production: [notaryApiUrl.production, citizenApiUrl.production],
};
const appBasePath = window.location.origin;
const getAppEnv = Object.keys(apiUrls).find(key => {
    if (key === 'local') {
        return appBasePath.includes('http://localhost');
    }
    return apiUrls[key].includes(appBasePath);
});
// Deciding the environment based on the base path
const viteEnv = import.meta.env.VITE_APP_ENV;
export const appEnv = viteEnv ?? getAppEnv ?? 'development';
