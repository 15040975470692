import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { chunkArray } from '@izimi/utils';
import DocumentsQueryKeys from './DocumentsQueryKeys';
export const documentsQueryKeys = new DocumentsQueryKeys('documents');
const invalidateDocumentsCache = (type) => () => {
    const queryClient = useQueryClient();
    const invalidateDocumentsVaultListCache = useCallback(() => {
        return queryClient.invalidateQueries({
            queryKey: documentsQueryKeys.getDocuments(type),
        });
    }, [queryClient]);
    return invalidateDocumentsVaultListCache;
};
export const useInvalidateDocumentsVaultListCache = invalidateDocumentsCache('vaultList');
export const useInvalidateDocumentsUnSeenListCache = invalidateDocumentsCache('unSeen');
export const useInvalidateDocumentsTrashListCache = invalidateDocumentsCache('trashList');
export const useInvalidateDocumentsNabanListCache = invalidateDocumentsCache('nabanList');
export const useInvalidateDocumentsInheritedListCache = invalidateDocumentsCache('inheritedList');
export const useInvalidateDocumentsSharedByYouListCache = invalidateDocumentsCache('sharedByYouList');
export const useInvalidateDocumentsSharedFromContactsListCache = invalidateDocumentsCache('sharedFromContactsList');
export const useInvalidateDocumentsSharedFromNotaryListCache = invalidateDocumentsCache('sharedFromNotaryList');
export const useInvalidateDocumentsSearchCache = invalidateDocumentsCache('searchDocuments');
export const useInvalidateDocumentCache = () => {
    const queryClient = useQueryClient();
    const invalidateDocumentCache = useCallback((id) => queryClient.invalidateQueries({
        queryKey: documentsQueryKeys.getDocument(id),
    }), [queryClient]);
    return invalidateDocumentCache;
};
export const useSetDocumentCache = () => {
    const queryClient = useQueryClient();
    const setDocumentCache = useCallback((doc) => queryClient.setQueryData(documentsQueryKeys.getDocument(doc.id), doc), [queryClient]);
    return setDocumentCache;
};
export const useUpdatePagedDocumentsCache = () => {
    const queryClient = useQueryClient();
    const updatePagedDocumentsCache = useCallback((type, cb) => {
        queryClient.setQueriesData({ queryKey: documentsQueryKeys.getDocuments(type) }, data => {
            if (data) {
                const allDocuments = data.pages.flatMap(page => page.documents);
                const updatedDocuments = cb(allDocuments, data);
                if (updatedDocuments.length === 0) {
                    return {
                        pageParams: [],
                        pages: [
                            {
                                documents: [],
                                total: 0,
                            },
                        ],
                    };
                }
                const pages = chunkArray(updatedDocuments, 15).map(chunk => ({
                    documents: chunk,
                    total: data.pages[0].total -
                        (allDocuments.length - updatedDocuments.length),
                }));
                return {
                    pageParams: data.pageParams,
                    pages,
                };
            }
            return data;
        });
    }, [queryClient]);
    return updatePagedDocumentsCache;
};
