import { lazy, Suspense } from 'react';
import {
  Navigate,
  Outlet,
  Route,
  Routes as RouterRoutes,
} from 'react-router-dom';

import {
  ErrorPage,
  UploadZone,
  UploadZonePositioningWrapper,
} from '@izimi/components';
import { appEnv } from '@izimi/core';
import Layout from 'common/components/Layout/Layout';
import LoginLayout from 'common/components/Layout/LoginLayout';
import { Routes } from 'navigation/types';

const RegistrationRoute = lazy(() => import('../pages/registration'));
const LoginRoute = lazy(() => import('../pages/login'));
const HomeRoute = lazy(() => import('../pages/home'));
const SharingRoute = lazy(() => import('../pages/sharing'));
const SharingFromContactsRoute = lazy(
  () => import('../pages/sharing/fromContacts'),
);
const SharingByYouRoute = lazy(() => import('../pages/sharing/byYou'));
const DebugRoute = lazy(() => import('../pages/debug'));

const ServicesRoute = lazy(() => import('../pages/services'));
const NewsDetailRoute = lazy(() => import('../pages/news/detail'));
const VaultTransferRoute = lazy(
  () => import('../pages/services/vault-transfer'),
);
const NabanRoute = lazy(() => import('../pages/services/naban'));
const NabanPreviewRoute = lazy(() => import('../pages/services/naban/preview'));
const CertificationInfoRoute = lazy(
  () => import('../pages/services/certification'),
);

const VaultRoute = lazy(() => import('../pages/vault'));
const VaultSearchRoute = lazy(() => import('../pages/vault/search'));
const VaultPreviewRoute = lazy(() => import('../pages/vault/preview'));

const AboutRoute = lazy(() => import('../pages/about'));
const TermsOfUseRoute = lazy(() => import('../pages/about/terms-of-use'));

const ProfileRoute = lazy(() => import('../pages/profile'));
const ConnectionsRoute = lazy(() => import('../pages/profile/connections'));
const DeactivateAccountRoute = lazy(
  () => import('../pages/profile/deactivate'),
);
const DeceasedUserRoute = lazy(() => import('../pages/profile/deceased'));
const EmailNotificationsRoute = lazy(
  () => import('../pages/profile/emailNotifications'),
);

const HistoryLogRoute = lazy(() => import('../pages/history-log'));
const TrashRoute = lazy(() => import('../pages/trash'));

const HelpInfoRoute = lazy(() => import('../pages/help/info'));
const HelpFeedbackRoute = lazy(() => import('../pages/help/feedback'));
const HelpReportProblemRoute = lazy(
  () => import('../pages/help/report-problem'),
);

const ItsmeRoute = lazy(() => import('../pages/itsme'));
const UnsubscribeRoute = lazy(() => import('../pages/unsubscribe'));

const LayoutRoutes = () => (
  <Suspense fallback={<Layout />}>
    <Outlet />
    <UploadZonePositioningWrapper>
      <UploadZone />
    </UploadZonePositioningWrapper>
  </Suspense>
);

const LoginLayoutRoutes = () => (
  <Suspense fallback={<LoginLayout />}>
    <Outlet />
  </Suspense>
);

const Router = () => (
  <RouterRoutes>
    <Route element={<LoginLayoutRoutes />}>
      <Route path={Routes.Registration} element={<RegistrationRoute />} />
      <Route path={Routes.Login} element={<LoginRoute />} />
      <Route path={Routes.Deactivation} element={<DeactivateAccountRoute />} />
      <Route path={Routes.DeceasedUser} element={<DeceasedUserRoute />} />
    </Route>

    <Route element={<LayoutRoutes />}>
      <Route path={Routes.Home} element={<HomeRoute />} />
      <Route element={<SharingRoute />} path={Routes.Sharing}>
        <Route
          index
          element={<Navigate to={Routes.SharingFromContacts} replace />}
        />
        <Route
          path={Routes.SharingFromContacts}
          element={<SharingFromContactsRoute />}
        />
        <Route path={Routes.SharingByYou} element={<SharingByYouRoute />} />
      </Route>
      {/* TODO: Remove contacts completely starting from 3.10 */}
      <Route
        path={Routes.Contacts}
        element={<Navigate to={Routes.Sharing} replace />}
      />
      <Route path={Routes.About} element={<AboutRoute />} />
      <Route path={Routes.TermsOfUse} element={<TermsOfUseRoute />} />
      {appEnv !== 'production' && (
        <Route path={Routes.Debug} element={<DebugRoute />} />
      )}

      <Route path={Routes.Services} element={<ServicesRoute />} />
      <Route path={Routes.NewsDetail} element={<NewsDetailRoute />} />
      <Route path={Routes.VaultTransfer} element={<VaultTransferRoute />} />
      <Route path={Routes.Naban} element={<NabanRoute />} />
      <Route path={Routes.NabanPreview} element={<NabanPreviewRoute />} />
      <Route
        path={Routes.CertificationInfo}
        element={<CertificationInfoRoute />}
      />

      <Route path={Routes.Vault} element={<VaultRoute />} />
      <Route path={Routes.VaultSearch} element={<VaultSearchRoute />} />
      <Route path={Routes.VaultPreview} element={<VaultPreviewRoute />} />

      <Route path={Routes.Profile} element={<ProfileRoute />} />
      <Route path={Routes.Connections} element={<ConnectionsRoute />} />
      <Route
        path={Routes.EmailNotifications}
        element={<EmailNotificationsRoute />}
      />

      <Route path={Routes.HistoryLog} element={<HistoryLogRoute />} />
      <Route path={Routes.Trash} element={<TrashRoute />} />

      <Route path={Routes.HelpInfo} element={<HelpInfoRoute />} />
      <Route path={Routes.HelpFeedback} element={<HelpFeedbackRoute />} />
      <Route
        path={Routes.HelpReportProblem}
        element={<HelpReportProblemRoute />}
      />
    </Route>
    <Route path={Routes.Itsme} element={<ItsmeRoute />} />
    <Route path={Routes.Unsubscribe} element={<UnsubscribeRoute />} />
    <Route path='*' element={<ErrorPage statusCode={404} />} />
  </RouterRoutes>
);

export default Router;
