class FetchService {
    providers;
    apiUrl;
    headers;
    token;
    mockApiKey;
    constructor(providers) {
        this.providers = providers;
        this.headers = {
            'Content-type': 'application/json',
            Accept: 'application/json',
        };
    }
    getToken() {
        return this.token ?? this.providers.getAccessToken?.();
    }
    setToken(token) {
        this.token = token;
    }
    setApiUrl(apiUrl) {
        this.apiUrl = apiUrl;
    }
    setMockApiKey(key) {
        this.mockApiKey = key;
    }
    get(url, options, headers) {
        return this.request({
            url,
            params: { method: 'GET' },
            headers,
            options,
        });
    }
    post(url, body, headers, options) {
        return this.request({
            url,
            params: {
                body: body instanceof FormData || typeof body === 'string'
                    ? body
                    : JSON.stringify(body),
                method: 'POST',
            },
            headers,
            options,
        });
    }
    async uploadFile(url, formData, onProgress, options) {
        const token = await this.getToken();
        const DONE_STATE = 4;
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            if (options?.signal && options?.signal.aborted) {
                reject(new Error('client.technical.uploadAborted'));
            }
            function abort() {
                xhr.abort();
            }
            if (options?.signal) {
                options?.signal.addEventListener('abort', abort);
                xhr.onreadystatechange = () => {
                    if (xhr.readyState === DONE_STATE) {
                        options?.signal.removeEventListener('abort', abort);
                    }
                };
            }
            xhr.onabort = () => {
                reject(new Error('client.technical.uploadAborted'));
            };
            xhr.open('POST', this.apiUrl + url);
            xhr.setRequestHeader('Authorization', `bearer ${token}`);
            xhr.responseType = 'text';
            xhr.onload = async () => {
                if (xhr.status >= 200 && xhr.status < 300) {
                    // the backend firewall might send a 200 OK with a html error page
                    // so we need to check if the the response is json and 200 OK
                    const text = await xhr.response;
                    try {
                        const json = JSON.parse(text);
                        return resolve(json);
                    }
                    catch {
                        return reject(this.providers.handleErrorResponse(xhr));
                    }
                }
                return reject(this.providers.handleErrorResponse(xhr));
            };
            xhr.onerror = () => reject(new TypeError('Network request failed'));
            xhr.upload.onprogress = onProgress;
            xhr.send(formData);
        });
    }
    put(url, body) {
        return this.request({
            url,
            params: {
                body: JSON.stringify(body),
                method: 'PUT',
            },
        });
    }
    delete(url, body) {
        return this.request({
            url,
            params: {
                body: JSON.stringify(body),
                method: 'DELETE',
            },
        });
    }
    async request({ url, params = {}, headers, cb, options, }) {
        if (!this.apiUrl) {
            throw new Error('The api url is undefined');
        }
        const token = await this.getToken();
        // Apply predefined headers first, then overwrite with custom headers
        const requestHeader = {
            headers: {
                'Accept-Language': this.providers.getLanguage(),
                'Izimi-App': this.providers.getAppSpec().app,
                'Izimi-Version': this.providers.getAppSpec().version,
                ...(this.mockApiKey && { 'x-api-key': this.mockApiKey }),
                ...(token && { Authorization: `bearer ${token}` }),
                ...(headers || this.headers),
            },
        };
        if (cb) {
            return cb(requestHeader.headers, this.apiUrl + url);
        }
        if (this.providers.logRequests) {
            console.debug('api request', { url: this.apiUrl + url, params });
        }
        const response = await fetch(this.apiUrl + url, {
            ...requestHeader,
            ...params,
        });
        return this.handleResponse(response, options);
    }
    async handleResponse(res, options) {
        if (!res.ok) {
            return this.providers.handleErrorResponse(res);
        }
        if (res.status === 204) {
            return Promise.resolve({});
        }
        if (options?.resAsBlob) {
            if (options?.withResponseHeaders) {
                return Promise.all([res.blob(), res.headers]);
            }
            return res.blob();
        }
        const text = await res.text();
        try {
            const json = JSON.parse(text);
            return json;
        }
        catch {
            return text;
        }
    }
}
export default FetchService;
