import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Button, Center, Flex, Link, Text } from '@chakra-ui/react';
import { ErrorIllustration } from './ErrorIllustration';
const helpUrls = {
    nl: 'https://www.izimi.be/nl/faq',
    fr: 'https://www.izimi.be/fr/faq',
    de: 'https://www.izimi.be/de/faq',
    en: 'https://www.izimi.be/en/faq',
};
const ErrorPage = ({ statusCode, title, message, withButton, buttonDestination, buttonLabel, }) => {
    const { t, i18n } = useTranslation('errors');
    const handleReload = () => {
        if (buttonDestination) {
            window.location.assign(buttonDestination);
        }
        else {
            window.location.reload();
        }
    };
    return (_jsxs(Center, { flexDir: 'column', gap: 2, position: 'relative', height: '100%', children: [_jsx(ErrorIllustration, {}), !!statusCode && (_jsx(Text, { variant: 'heading5', color: 'neutral400', children: statusCode })), _jsx(Text, { as: 'h1', variant: 'heading1', children: title ?? t(`error${statusCode}.title`) }), _jsx(Text, { as: 'h3', variant: 'paragraph', children: message ?? t(`error${statusCode}.message`) }), _jsxs(Flex, { flexDir: 'column', alignItems: 'center', gap: 2, mt: 4, children: [withButton && (_jsx(Button, { variant: 'primary500', onClick: handleReload, children: buttonLabel ?? t('shared:action.reload') })), _jsx(Link, { href: helpUrls[i18n.language], variant: 'heading6', isExternal: true, children: t('shared:navigation.help') })] })] }));
};
export default ErrorPage;
