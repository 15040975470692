import { useCallback } from 'react';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
export var SortId;
(function (SortId) {
    SortId["ModificationDate"] = "modificationDate";
    SortId["Name"] = "name";
    SortId["CreationDate"] = "creationDate";
    SortId["TrashingDate"] = "trashingDate";
    SortId["State"] = "state";
})(SortId || (SortId = {}));
const initialState = {
    vault: {
        sorting: {
            id: SortId.ModificationDate,
            desc: true,
        },
    },
    trash: {
        sorting: {
            id: SortId.TrashingDate,
            desc: true,
        },
    },
    fromContacts: {
        sorting: {
            id: SortId.State,
            desc: true,
        },
    },
    byYou: {
        sorting: {
            id: SortId.Name,
            desc: true,
        },
    },
    setActiveSorting: () => { },
};
const useDocumentSortingStore = create()(persist(set => ({
    ...initialState,
    setActiveSorting: (activeSorting, tableType) => set(state => ({
        ...state,
        [tableType]: {
            sorting: {
                ...state[tableType].sorting,
                ...activeSorting,
            },
        },
    })),
}), {
    name: 'documentSortingStore',
    version: 3,
}));
export const useDocumentSorting = (tableType) => {
    const state = useDocumentSortingStore();
    const { sorting } = state[tableType];
    const handleSetActiveSorting = useCallback((sortingValues) => {
        return state.setActiveSorting(sortingValues, tableType);
    }, [state, tableType]);
    return {
        activeSorting: sorting,
        setActiveSorting: handleSetActiveSorting,
    };
};
