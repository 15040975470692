import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@chakra-ui/react';
import { useSelect } from 'downshift';
import { CustomIcon } from '../icon';
import BaseSelect from './BaseSelect';
const Select = ({ isDisabled, isPending, size, items, onSelectedItemChange, width, selectedItem, idProperty = 'id', labelProperty = 'label', noItemsMessage, initialHighlightedIndex, isInvalid, placeholder, buttonLabelStyle, }) => {
    const { t } = useTranslation('shared');
    const [inputItems, setInputItems] = useState(items);
    useEffect(() => {
        setInputItems(items);
    }, [items]);
    const { isOpen, getMenuProps, getToggleButtonProps, highlightedIndex, getItemProps, inputValue, } = useSelect({
        items: inputItems,
        selectedItem,
        initialHighlightedIndex,
        itemToString: item => item?.[labelProperty],
        onSelectedItemChange({ selectedItem }) {
            onSelectedItemChange(selectedItem);
        },
    });
    return (_jsx(BaseSelect, { items: inputItems, getItemProps: getItemProps, getMenuProps: getMenuProps, highlightedIndex: highlightedIndex, inputValue: inputValue, isOpen: isOpen, selectedItem: selectedItem, size: size, idProperty: idProperty, labelProperty: labelProperty, onSelectedItemChange: onSelectedItemChange, noItemsMessage: noItemsMessage, isInvalid: isInvalid, children: _jsx(Button, { ...getToggleButtonProps(), borderColor: isInvalid && 'secondary400', size: size, isLoading: isPending, isDisabled: isDisabled || isPending, justifyContent: isPending ? 'center' : 'space-between', variant: isOpen ? 'selectButtonOpen' : 'selectButton', width: width, rightIcon: _jsx(CustomIcon, { size: 20, name: 'chevron-down', color: isOpen ? 'primary500' : 'neutral400' }), ...buttonLabelStyle, children: selectedItem
                ? selectedItem[labelProperty]
                : placeholder ?? t('select.selectItem') }) }));
};
export default Select;
