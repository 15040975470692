import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { cloneElement, forwardRef } from 'react';
import { useMeasure } from 'react-use';
import { Box, Flex, Grid, Text } from '@chakra-ui/react';
import { BaseLayout } from './BaseLayout';
const DEFAULT_PADDING = 7;
const FIXED_FOOTER_WIDTH = 'calc(100% - 100px)'; // 100px is the width of the sidebar
export const BaseIllustrationLayout = forwardRef(({ title, subTitle, titleAction, children, renderHeader, renderStickyFooter, Illustration, navigation, ...chakraProps }, ref) => {
    const [footerRef, { height: footerHeight }] = useMeasure();
    return (_jsx(BaseLayout, { navigation: navigation, ...chakraProps, children: _jsxs(Grid, { templateColumns: { base: '1fr', xl: '1.5fr 1fr' }, height: '100%', children: [_jsxs(Flex, { flexDir: 'column', p: DEFAULT_PADDING, paddingBottom: { base: `${footerHeight}px`, xl: DEFAULT_PADDING }, overflow: 'auto', ref: ref, ...chakraProps, children: [renderHeader, _jsxs(Flex, { flexDir: 'column', maxW: 'xl', marginInline: 'auto', mt: 10, flex: 1, children: [_jsxs(Flex, { justifyContent: 'space-between', children: [_jsx(Text, { as: 'h1', variant: 'heading1', mb: 2, children: title }), titleAction] }), _jsx(Text, { variant: 'lead', mb: 10, width: titleAction ? '80%' : 'full', children: subTitle }), children] }), renderStickyFooter && (_jsx(Box, { ref: footerRef, pos: { base: 'fixed', xl: 'sticky' }, bottom: { base: 0, xl: -DEFAULT_PADDING }, ml: { base: -DEFAULT_PADDING, xl: -DEFAULT_PADDING }, mr: { base: 0, xl: -DEFAULT_PADDING }, mb: { base: 0, xl: -DEFAULT_PADDING }, w: { base: 'full', md: FIXED_FOOTER_WIDTH, xl: 'revert' }, bgColor: 'white', boxShadow: 'card', children: renderStickyFooter }))] }), Illustration &&
                    cloneElement(Illustration, {
                        display: { base: 'none', xl: 'flex' },
                    })] }) }));
});
