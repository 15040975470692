import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiDocumentSource } from '@izimi/api';
import { logout } from '@izimi/auth';
import type { NavigationMenuItem } from '@izimi/components';
import { appEnv } from '@izimi/core';
import { useModules } from '@izimi/modules';

import { Routes } from './types';

const isProduction = appEnv === 'production';

export default function useNavigationMenu() {
  const { t } = useTranslation('shared');
  const {
    documents: { usePagedDocuments },
    contacts: { usePagedContacts },
  } = useModules();

  const { total: totalFromNotary } = usePagedDocuments({
    params: {
      sources: [ApiDocumentSource.Study],
      states: 'PENDING',
      limit: 1,
    },
    type: 'sharedFromNotaryList',
  });

  const { total: totalPendingDocuments } = usePagedDocuments({
    params: {
      sources: [ApiDocumentSource.Shared],
      states: 'PENDING',
      limit: 1,
    },
    type: 'sharedFromContactsList',
  });

  const { total: totalContactRequests } = usePagedContacts({
    params: { status: ['PENDING'], pageSize: 1 },
    type: 'contactRequests',
  });

  const mainMenu = useMemo<NavigationMenuItem<Routes>[]>(() => {
    const menuItems: NavigationMenuItem<Routes>[] = [
      {
        href: Routes.Home,
        label: t('navigation.home'),
        customIconName: 'home',
      },
      {
        href: Routes.Vault,
        label: t('navigation.vault'),
        customIconName: 'vault',
        badge: totalFromNotary,
      },
      {
        href: Routes.Sharing,
        label: t('navigation.sharing'),
        customIconName: 'user-share',
        badge: totalPendingDocuments + totalContactRequests,
      },
      {
        href: Routes.Services,
        label: t('navigation.services'),
        customIconName: 'briefcase',
      },
    ];

    if (!isProduction) {
      const debugItem: NavigationMenuItem<Routes> = {
        href: Routes.Debug,
        label: t('navigation.debug'),
        customIconName: 'bug',
      };

      menuItems.push(debugItem);
    }

    return menuItems;
  }, [t, totalFromNotary, totalPendingDocuments, totalContactRequests]);

  const helpMenu = useMemo<NavigationMenuItem<Routes>[]>(
    () => [
      {
        href: Routes.HelpInfo,
        customIconName: 'mail',
        label: t('navigation.support'),
      },
      {
        href: Routes.HelpReportProblem,
        customIconName: 'bug',
        label: t('navigation.bugReport'),
      },
      {
        href: Routes.HelpFeedback,
        customIconName: 'like',
        label: t('navigation.feedback'),
      },
    ],
    [t],
  );

  const userMenu = useMemo<NavigationMenuItem<Routes>[]>(
    () => [
      {
        href: Routes.Profile,
        customIconName: 'user',
        label: t('navigation.profile'),
      },
      {
        href: Routes.Trash,
        customIconName: 'trash',
        label: t('navigation.trashBin'),
      },
      {
        href: Routes.HistoryLog,
        customIconName: 'clock',
        label: t('navigation.history'),
      },
      {
        href: Routes.About,
        customIconName: 'help-circle',
        label: t('navigation.about'),
      },
      {
        href: Routes.Hash,
        customIconName: 'logout',
        label: t('navigation.logout'),
        onClick: logout,
      },
    ],
    [t],
  );

  return {
    mainMenu,
    helpMenu,
    userMenu,
  };
}
